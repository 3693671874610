import React from "react";

function linkedin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      cursor="pointer"
      {...props}
    >
      <circle cx="13.75" cy="13.75" r="13.75" fill="black" />
      <path
        d="M18.8333 8H8.16667C7.98986 8 7.82029 8.07024 7.69526 8.19526C7.57024 8.32029 7.5 8.48986 7.5 8.66667V19.3333C7.5 19.5101 7.57024 19.6797 7.69526 19.8047C7.82029 19.9298 7.98986 20 8.16667 20H18.8333C19.0101 20 19.1797 19.9298 19.3047 19.8047C19.4298 19.6797 19.5 19.5101 19.5 19.3333V8.66667C19.5 8.48986 19.4298 8.32029 19.3047 8.19526C19.1797 8.07024 19.0101 8 18.8333 8ZM11.0593 18.2247H9.278V12.498H11.0593V18.2247ZM10.1687 11.716C9.89496 11.716 9.63247 11.6073 9.43893 11.4137C9.24539 11.2202 9.13667 10.9577 9.13667 10.684C9.13667 10.4103 9.24539 10.1478 9.43893 9.95427C9.63247 9.76073 9.89496 9.652 10.1687 9.652C10.4424 9.652 10.7049 9.76073 10.8984 9.95427C11.0919 10.1478 11.2007 10.4103 11.2007 10.684C11.2007 10.9577 11.0919 11.2202 10.8984 11.4137C10.7049 11.6073 10.4424 11.716 10.1687 11.716ZM17.7253 18.2247H15.946V15.44C15.946 14.776 15.934 13.922 15.0207 13.922C14.094 13.922 13.9533 14.646 13.9533 15.3933V18.2253H12.1753V12.4987H13.882V13.2813H13.9067C14.1433 12.8313 14.7247 12.3567 15.5893 12.3567C17.392 12.3567 17.7247 13.542 17.7247 15.0847V18.2247H17.7253Z"
        fill="white"
      />
    </svg>
  );
}

export default linkedin;
