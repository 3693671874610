import React from "react";

function close(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        d="M24 4C20.0444 4 16.1776 5.17298 12.8886 7.37061C9.59962 9.56824 7.03617 12.6918 5.52242 16.3463C4.00867 20.0009 3.6126 24.0222 4.3843 27.9018C5.15601 31.7814 7.06082 35.3451 9.85787 38.1421C12.6549 40.9392 16.2186 42.844 20.0982 43.6157C23.9778 44.3874 27.9992 43.9913 31.6537 42.4776C35.3082 40.9638 38.4318 38.4004 40.6294 35.1114C42.827 31.8224 44 27.9556 44 24C44 21.3736 43.4827 18.7728 42.4776 16.3463C41.4725 13.9198 39.9993 11.715 38.1421 9.85786C36.285 8.00069 34.0802 6.5275 31.6537 5.52241C29.2272 4.51732 26.6264 4 24 4ZM29.42 26.58C29.6075 26.7659 29.7563 26.9871 29.8578 27.2308C29.9593 27.4746 30.0116 27.736 30.0116 28C30.0116 28.264 29.9593 28.5254 29.8578 28.7691C29.7563 29.0129 29.6075 29.2341 29.42 29.42C29.2341 29.6075 29.0129 29.7562 28.7692 29.8578C28.5254 29.9593 28.264 30.0116 28 30.0116C27.736 30.0116 27.4746 29.9593 27.2309 29.8578C26.9871 29.7562 26.7659 29.6075 26.58 29.42L24 26.82L21.42 29.42C21.2341 29.6075 21.0129 29.7562 20.7692 29.8578C20.5254 29.9593 20.264 30.0116 20 30.0116C19.736 30.0116 19.4746 29.9593 19.2309 29.8578C18.9871 29.7562 18.7659 29.6075 18.58 29.42C18.3925 29.2341 18.2438 29.0129 18.1422 28.7691C18.0407 28.5254 17.9884 28.264 17.9884 28C17.9884 27.736 18.0407 27.4746 18.1422 27.2308C18.2438 26.9871 18.3925 26.7659 18.58 26.58L21.18 24L18.58 21.42C18.2034 21.0434 17.9918 20.5326 17.9918 20C17.9918 19.4674 18.2034 18.9566 18.58 18.58C18.9566 18.2034 19.4674 17.9918 20 17.9918C20.5326 17.9918 21.0434 18.2034 21.42 18.58L24 21.18L26.58 18.58C26.9566 18.2034 27.4674 17.9918 28 17.9918C28.5326 17.9918 29.0434 18.2034 29.42 18.58C29.7966 18.9566 30.0082 19.4674 30.0082 20C30.0082 20.5326 29.7966 21.0434 29.42 21.42L26.82 24L29.42 26.58Z"
        fill="#EB5757"
      />
    </svg>
  );
}

export default close;
