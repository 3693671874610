import React from "react";

function link(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      cursor="pointer"
      {...props}
    >
      <circle cx="24" cy="24" r="24" fill="black" />
      <path
        d="M20 20C17.752 20 16 21.752 16 24C16 26.248 17.752 28 20 28H22C22.2652 28 22.5196 28.1054 22.7071 28.2929C22.8946 28.4804 23 28.7348 23 29C23 29.2652 22.8946 29.5196 22.7071 29.7071C22.5196 29.8946 22.2652 30 22 30H20C16.648 30 14 27.352 14 24C14 20.648 16.648 18 20 18H22C22.2652 18 22.5196 18.1054 22.7071 18.2929C22.8946 18.4804 23 18.7348 23 19C23 19.2652 22.8946 19.5196 22.7071 19.7071C22.5196 19.8946 22.2652 20 22 20H20ZM25 19C25 18.7348 25.1054 18.4804 25.2929 18.2929C25.4804 18.1054 25.7348 18 26 18H28C31.352 18 34 20.648 34 24C34 27.352 31.352 30 28 30H26C25.7348 30 25.4804 29.8946 25.2929 29.7071C25.1054 29.5196 25 29.2652 25 29C25 28.7348 25.1054 28.4804 25.2929 28.2929C25.4804 28.1054 25.7348 28 26 28H28C30.248 28 32 26.248 32 24C32 21.752 30.248 20 28 20H26C25.7348 20 25.4804 19.8946 25.2929 19.7071C25.1054 19.5196 25 19.2652 25 19ZM19 24C19 23.7348 19.1054 23.4804 19.2929 23.2929C19.4804 23.1054 19.7348 23 20 23H28C28.2652 23 28.5196 23.1054 28.7071 23.2929C28.8946 23.4804 29 23.7348 29 24C29 24.2652 28.8946 24.5196 28.7071 24.7071C28.5196 24.8946 28.2652 25 28 25H20C19.7348 25 19.4804 24.8946 19.2929 24.7071C19.1054 24.5196 19 24.2652 19 24Z"
        fill="white"
      />
    </svg>
  );
}

export default link;
