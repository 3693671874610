import React from "react";

function twitter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
      viewBox="0 0 28 28"
      {...props}
    >
      <circle cx="14.25" cy="13.75" r="13.75" fill="black" />
      <g clip-path="url(#clip0_267_132)">
        <path
          d="M11.526 21C17.564 21 20.867 15.997 20.867 11.666C20.867 11.526 20.867 11.384 20.861 11.244C21.5041 10.7784 22.0591 10.2021 22.5 9.54196C21.8993 9.80681 21.2628 9.98137 20.611 10.06C21.2975 9.6496 21.8117 9.00393 22.058 8.24296C21.413 8.62508 20.707 8.89333 19.971 9.03596C19.4762 8.50898 18.8214 8.15988 18.1081 8.04272C17.3948 7.92556 16.6627 8.04688 16.0253 8.3879C15.388 8.72891 14.8808 9.27059 14.5824 9.92902C14.2841 10.5875 14.2112 11.3259 14.375 12.03C13.0697 11.9645 11.7928 11.6254 10.627 11.0347C9.46127 10.444 8.43268 9.6148 7.608 8.60096C7.18934 9.32404 7.06157 10.1794 7.25065 10.9932C7.43972 11.8071 7.93145 12.5185 8.626 12.983C8.10554 12.9653 7.59652 12.8255 7.14 12.575V12.62C7.1409 13.3774 7.40331 14.1114 7.88287 14.6977C8.36244 15.2841 9.02975 15.6868 9.772 15.838C9.49026 15.9156 9.19923 15.9543 8.907 15.953C8.70098 15.9536 8.49538 15.9345 8.293 15.896C8.50279 16.548 8.91127 17.1181 9.46125 17.5264C10.0112 17.9347 10.6751 18.1608 11.36 18.173C10.1966 19.0868 8.7594 19.5824 7.28 19.58C7.01932 19.5811 6.75882 19.566 6.5 19.535C8.00151 20.4922 9.7453 21.0005 11.526 21Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_267_132">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(6.5 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default twitter;
