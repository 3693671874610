import React from "react";

function fola(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="a"
      viewBox="0 0 1000 1000"
      preserveAspectRatio="none"
      cursor="pointer"
      {...props}
    >
      <path d="M563.06,563.06l-.59-6.2-4.43-.15-1.18,5.46c1.62,2.07,2.51,4.73,2.36,7.24,.59-2.51,1.92-4.73,3.84-6.35h0Zm-3.54-1.62c.44-.44,1.03-.44,1.48,0h0c.44,.44,.44,1.03,0,1.33l-.15,.15c-.44,.44-1.03,.44-1.48,0h0c-.3-.44-.3-1.03,.15-1.48-.15,0,0,0,0,0h0Z" />
      <path d="M581.22,482.87c-8.12,13.14-16.24,37.51-20.97,64.09h0l-.89,4.58,2.66,.15,.15-3.69c2.51-14.32,9.6-52.13,35.44-84.62,6.79-8.27,12.85-16.98,18.46-26.14-2.07,3.54-6.79,8.71-9.3,11.81-6.5,8.12-15.06,16.69-25.55,33.96v-.15Z" />
      <path d="M552.42,529.98c.59,9.89,3.25,16.83,4.87,18.31l1.48-.59c1.18-10.34,10.63-49.77,19.94-64.24,7.24-11.52,15.51-22.3,24.96-32.19,2.51-2.81,4.87-5.76,6.94-8.86,2.51-3.84,4.87-7.97,6.94-11.81l-6.79,8.86,1.77-7.38-4.87,10.19,1.92-10.04c-7.38,3.69-14.18,8.56-19.79,14.47-1.62,5.17-2.81,10.48-3.1,15.8-1.48-3.99-.89-11.08-.89-11.08-1.77,1.62-7.83,8.71-7.83,8.71-2.66,4.43-1.03,13-1.03,13-1.77-2.95-1.48-10.04-1.48-10.04-8.71,9.89-12.7,23.18-12.7,23.18-1.62,12.4,5.61,18.9,3.25,17.57s-4.43-8.71-4.43-8.71c-7.53,14.77-6.94,20.38-6.94,20.38-.3,5.32,2.36,18.16,2.36,18.16-1.48-.74-2.66-7.53-2.66-7.53-.44,3.99,.44,8.12,2.51,11.67-1.18,.15-4.43-7.83-4.43-7.83h0Z" />
      <path d="M618.88,434.29c-2.07,4.13-4.43,7.97-6.94,11.81-6.35,9.89-12.7,18.46-12.7,18.46-26.14,30.86-33.37,68.96-35.59,81.81l3.25-1.18c.74-1.33,2.22-2.66,8.71-7.09,4.87-3.4,8.71-7.83,11.52-13,0,0-6.65,7.38-10.63,8.27-3.1,.89-6.05,2.36-8.71,4.13,0,0-2.22-.3,6.94-5.17,9.16-4.87,21.56-21.41,21.56-21.41,0,0-5.32,4.73-8.12,5.46s-9.89,3.4-9.89,3.4c4.28-2.66,8.86-3.54,15.06-8.71,6.2-5.17,5.91-4.87,5.91-4.87,7.09-5.46,7.53-15.06,7.53-15.06-1.18,1.03-11.37,6.05-16.24,6.35s-11.96,4.13-11.96,4.13c6.35-4.87,8.27-4.28,9.89-5.02s7.53-2.22,17.28-9.89,11.37-13.59,11.37-13.59l-7.09,3.69c3.4-2.07,6.35-4.73,9.01-7.68-2.66,1.62-5.32,3.1-8.27,4.43,3.1-1.92,6.05-4.13,8.56-6.65,2.51-3.99,3.69-8.71,3.4-13.59-4.13,3.4-9.01,5.61-14.32,6.65,11.08-4.73,13.29-8.71,14.62-10.04s1.03-4.28,1.03-4.28c-3.54,2.66-7.38,5.02-11.37,6.65,4.28-2.36,8.12-5.46,11.52-9.16l-1.18-3.54c-1.92,2.07-4.13,3.84-6.5,5.32l5.76-7.38c-.74-1.48-1.92-2.66-3.4-3.25h0Z" />
      <path d="M612.97,529.68h6.35l19.49-53.01h11.22l19.49,53.01h6.35v6.94h-22v-6.94h7.09l-3.99-11.08h-25.25l-3.99,11.08h7.09v6.94h-21.86v-6.94h0Zm21.12-17.57h20.67l-10.34-28.8-10.34,28.8Z" />
      <path d="M685.92,530.42v-32.93h-6.79v-6.2h14.91v5.46c2.66-3.69,6.94-5.91,11.52-6.05,6.5,0,11.22,2.95,13.73,8.56,2.81-4.87,6.94-8.56,13.44-8.56,9.6,0,15.65,6.35,15.65,18.75v20.97h6.79v6.05h-14.91v-25.25c0-9.6-3.4-14.03-9.45-14.03-5.32,0-7.97,5.17-9.6,10.19,0,.59,.15,1.33,.15,1.92v20.97h6.65v6.2h-14.77v-25.25c0-9.6-3.4-14.03-9.45-14.03-5.17,0-8.27,5.32-9.6,10.63v22.59h6.79v6.2h-21.71v-6.2h6.65Z" />
      <path d="M781.31,537.07c-12.4,0-22.15-8.71-22.15-23.33s9.6-23.04,22.15-23.04,22.15,8.71,22.15,23.04-9.6,23.33-22.15,23.33Zm0-39.13c-7.83,0-13.73,6.2-13.73,15.8s5.91,16.1,13.73,16.1,14.03-6.35,14.03-16.1-6.2-15.8-14.03-15.8Z" />
      <path d="M832.11,537.07c-12.4,0-22.15-8.71-22.15-23.33s9.6-23.04,22.15-23.04,22.15,8.71,22.15,23.04-9.6,23.33-22.15,23.33Zm0-39.13c-7.83,0-13.73,6.2-13.73,15.8s5.76,16.1,13.73,16.1,14.03-6.35,14.03-16.1-6.2-15.8-14.03-15.8Z" />
      <circle cx="782.2" cy="545.78" r="3.4" />
      <path d="M771.42,472.98c1.03-.15,2.22-.15,3.4-.15,.3,.59,.74,1.03,1.18,1.48l2.36,2.95,2.36,3.1,1.18,1.48c-.3,1.03-.59,2.07-.89,3.1-1.03,.15-2.22,.15-3.25,.15l-1.18-1.48-2.36-2.95-2.36-2.95c-.44-.44-.74-1.03-1.18-1.48,0-1.03,.3-2.07,.74-3.25Z" />
      <path d="M635.27,458.21c1.03-.15,2.22-.15,3.4-.15,.3,.59,.74,1.03,1.18,1.48l2.36,2.95,2.36,2.95,1.18,1.48c-.3,1.03-.59,2.07-.89,3.1-1.03,.15-2.22,.15-3.25,.15l-1.18-1.48-2.36-2.95-2.36-2.95c-.44-.44-.74-1.03-1.18-1.48,0-1.03,.3-2.07,.74-3.1Z" />
      <path d="M838.76,472.98c.44,1.03,.74,2.07,1.03,3.25-.44,.44-.74,1.03-1.18,1.48l-2.36,2.95-2.36,2.95-1.18,1.48c-1.03,0-2.22,0-3.25-.15-.44-1.03-.74-2.07-.89-3.1l1.18-1.48,2.36-2.95,2.36-2.95c.44-.59,.74-1.03,1.18-1.48,.89-.15,2.07,0,3.1,0Z" />
      <path d="M145.74,536.62v-7.24h6.79v-45.63h-6.79v-7.24h49.03v18.31h-6.5l-.74-11.08h-26.29v19.79h19.79v6.5h-19.94v19.49h6.79v7.09h-22.15Z" />
      <path d="M219.72,537.07c-12.4,0-22.15-8.71-22.15-23.33s9.6-23.04,22.15-23.04,22.15,8.71,22.15,23.04-9.6,23.33-22.15,23.33Zm0-39.13c-7.83,0-13.73,6.2-13.73,15.8s5.91,16.1,13.73,16.1,14.03-6.35,14.03-16.1-6.05-15.8-14.03-15.8Z" />
      <path d="M246,473.71h14.91v56.85h6.94v6.2h-21.86v-6.2h6.94v-50.65h-6.94v-6.2Z" />
      <path d="M292.23,490.84c5.61-.3,11.08,1.92,14.91,6.05v-5.61h8.12v39.13h6.94v6.05h-14.91v-5.61c-3.84,4.13-9.3,6.2-14.91,6.05-11.52,0-20.23-8.71-20.23-23.33s8.56-22.74,20.08-22.74Zm-11.96,23.18c0,9.6,6.05,16.1,14.03,16.1,6.05,.15,11.37-3.99,12.85-9.89v-12.26c-1.48-5.91-6.79-10.04-12.85-9.89-7.97-.15-14.03,6.2-14.03,15.95h0Z" />
      <path d="M342.43,473.71v23.33c3.99-4.13,9.45-6.35,15.06-6.2,11.52,0,20.08,8.71,20.08,23.04s-8.56,23.33-20.08,23.33c-5.76,.15-11.22-2.22-15.06-6.35v5.91h-14.91v-6.2h6.79v-50.8h-6.79v-6.05h14.91Zm0,46.22c1.33,6.05,6.65,10.34,12.85,10.19,7.97,0,14.03-6.5,14.03-16.1s-6.05-16.1-14.03-16.1c-6.05,0-11.37,4.13-12.85,10.19v11.81Z" />
      <path d="M406.23,537.07c-12.4,0-22.15-8.71-22.15-23.33s9.6-23.04,22.15-23.04,22.15,8.71,22.15,23.04-9.6,23.33-22.15,23.33Zm0-39.13c-7.83,0-13.88,6.2-13.88,15.8s5.91,16.1,13.88,16.1,14.03-6.35,14.03-16.1-6.05-15.8-14.03-15.8Z" />
      <path d="M439.31,530.42v-32.93h-6.79v-6.2h14.91v5.46c2.66-3.69,6.94-5.91,11.52-6.05,6.5,0,11.22,2.95,13.73,8.56,2.81-4.87,6.94-8.56,13.44-8.56,9.6,0,15.65,6.35,15.65,18.75v20.97h6.79v6.05h-14.91v-25.25c0-9.6-3.4-14.03-9.45-14.03-5.32,0-7.97,5.17-9.6,10.19,0,.59,.15,1.33,.15,1.92v20.97h6.65v6.2h-14.77v-25.25c0-9.6-3.4-14.03-9.45-14.03-5.17,0-8.27,5.32-9.6,10.63v22.59h6.79v6.2h-21.71v-6.2h6.65Z" />
      <path d="M513.73,491.29h14.91v39.13h6.94v6.2h-22v-6.2h6.94v-32.93h-6.94l.15-6.2Z" />
      <circle cx="219.87" cy="545.19" r="3.4" />
      <path d="M399.44,472.98c1.03-.15,2.22-.15,3.4-.15,.3,.59,.74,1.03,1.18,1.48l2.36,2.95,2.36,2.95,1.18,1.48c-.3,1.03-.59,2.07-.89,3.1-1.03,.15-2.22,.15-3.25,.15l-1.18-1.48-2.36-2.95-2.36-2.95c-.44-.44-.74-1.03-1.18-1.48,0-1.03,.3-2.07,.74-3.1Z" />
      <path d="M532.93,472.98c.44,1.03,.74,2.07,1.03,3.25-.44,.44-.74,1.03-1.18,1.48l-2.36,2.95-2.36,2.95-1.18,1.48c-1.03,0-2.22,0-3.25-.15-.44-1.03-.74-2.07-.89-3.1l1.18-1.48,2.36-2.95,2.36-2.95c.44-.59,.74-1.03,1.18-1.48,.89-.15,1.92,0,3.1,0Z" />
      <path d="M302.42,472.98c.44,1.03,.74,2.07,.89,3.25-.44,.44-.74,1.03-1.18,1.48l-2.36,2.95-2.36,2.95-1.18,1.48c-1.03,0-2.22,0-3.25-.15-.44-1.03-.74-2.07-.89-3.1l1.18-1.48,2.36-2.95,2.36-2.95c.44-.59,.74-1.03,1.18-1.48,1.03-.15,2.22,0,3.25,0Z" />
    </svg>
  );
}

export default fola;
