import React from "react";

function github(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
      cursor="pointer"
      {...props}
    >
      <circle cx="14.25" cy="13.75" r="13.75" fill="black" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0173 7.33325C10.3446 7.33325 7.36798 10.3099 7.36798 13.9826C7.36798 16.9199 9.27265 19.4126 11.9153 20.2926C12.248 20.3526 12.368 20.1479 12.368 19.9719C12.368 19.8139 12.3626 19.3953 12.3606 18.8413C10.5106 19.2426 10.12 17.9493 10.12 17.9493C9.81865 17.1813 9.38198 16.9766 9.38198 16.9766C8.77865 16.5639 9.42798 16.5733 9.42798 16.5733C10.096 16.6199 10.446 17.2586 10.446 17.2586C11.0393 18.2746 12.0033 17.9813 12.3806 17.8113C12.4413 17.3813 12.6146 17.0879 12.804 16.9219C11.328 16.7546 9.77598 16.1839 9.77598 13.6353C9.77598 12.9106 10.0353 12.3159 10.4586 11.8519C10.3913 11.6833 10.1613 11.0066 10.5246 10.0919C10.5246 10.0919 11.0826 9.91259 12.3526 10.7726C12.895 10.625 13.4545 10.5497 14.0166 10.5486C14.5788 10.5495 15.1383 10.6248 15.6806 10.7726C16.9513 9.91192 17.5086 10.0919 17.5086 10.0919C17.872 11.0066 17.644 11.6833 17.5746 11.8519C18.0013 12.3159 18.2573 12.9099 18.2573 13.6353C18.2573 16.1906 16.704 16.7519 15.2226 16.9166C15.4593 17.1219 15.6726 17.5273 15.6726 18.1473C15.6726 19.0366 15.6646 19.7539 15.6646 19.9719C15.6646 20.1499 15.7833 20.3566 16.1226 20.2913C18.764 19.4099 20.6666 16.9193 20.6666 13.9826C20.6666 10.3099 17.69 7.33325 14.0173 7.33325Z"
        fill="white"
      />
    </svg>
  );
}

export default github;
