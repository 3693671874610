import React from "react";

function arrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 14"
      fill="none"
      cursor="pointer"
      {...props}
    >
      <path
        style={{ fill: "var(--svgfill)" }}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 7.00003C0.5 6.80112 0.579018 6.61036 0.71967 6.4697C0.860322 6.32905 1.05109 6.25003 1.25 6.25003H18.9395L14.219 1.53103C14.0782 1.3902 13.9991 1.1992 13.9991 1.00003C13.9991 0.800868 14.0782 0.609862 14.219 0.469032C14.3598 0.328202 14.5508 0.249084 14.75 0.249084C14.9492 0.249084 15.1402 0.328202 15.281 0.469032L21.281 6.46903C21.3508 6.5387 21.4063 6.62146 21.4441 6.71258C21.4819 6.8037 21.5013 6.90138 21.5013 7.00003C21.5013 7.09868 21.4819 7.19636 21.4441 7.28748C21.4063 7.3786 21.3508 7.46136 21.281 7.53103L15.281 13.531C15.1402 13.6719 14.9492 13.751 14.75 13.751C14.5508 13.751 14.3598 13.6719 14.219 13.531C14.0782 13.3902 13.9991 13.1992 13.9991 13C13.9991 12.8009 14.0782 12.6099 14.219 12.469L18.9395 7.75003H1.25C1.05109 7.75003 0.860322 7.67102 0.71967 7.53036C0.579018 7.38971 0.5 7.19895 0.5 7.00003Z"
      />
    </svg>
  );
}

export default arrow;
